import React, { useEffect, useState } from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Alert, AlertTitle, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import 'App.css'
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';

export default function Install(){

    const [server, setServer] = useState("")


    useEffect(function(){
        fetch("/ext.php?op=server_v")
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.text(); // или response.text() если ожидается другой тип данных
        })
        .then(data => {
            if(data.length>20) data = "4.999"
            setServer("server_v."+data)
        })
        .catch(error => {
            // Обработка ошибок
            console.error('There was a problem with the fetch operation:', error);
        });
    }, [])

    const [openModal, setOpenModal] = useState(false)
    const [stepModal, setStepModal] = useState(0)
    const handleOpen = (p) => {
        setOpenModal(true)
        setStepModal(p.target.attributes.rel.value)
    };
    const handleClose = () => {
        setOpenModal(false);
    };



    function Item(props){

        const img = "/sources/astra_step" + props.step + ".jpg"
        const alt = "Настройка Astra шаг " + props.step
        const h1 = "Шаг " + props.step

        return (
            <div style={{border: "1px solid gray", padding: "1px"}}>
                <center><h3>{h1}</h3></center>
                <img src={img} alt={alt} rel={props.step} width="95%" onClick={handleOpen} style={{cursor:"pointer"}} />
            </div>
        )
    }


    


    return (
        <>
            <h1><ElectricBoltIcon /> Установка сервера</h1>

            <p>
                Установка системы не представляет сложностей.
                Сервер TVCAS4 состоит из одного исполняемого файла для Linux.
                В архиве он называется <b>server_v.4.XXX</b>, но вы можете переименовывать его по своему усмотрению.
                Скачайте архив <a href="https://tvcas.com/tvcas4.tar.gz">tvcas4.tar.gz</a>,
                распакуйте его в любое удобное место на диске и запустите <b>server_v.4.XXX</b>.
            </p>

            <Alert>
                <AlertTitle>Для установки наберите в терминале Linux</AlertTitle>
                <code>
                    wget https://tvcas.com/tvcas4.tar.gz<br />
                    tar -xzvf tvcas4.tar.gz<br />
                    ./tvcas4/{server}
                </code>

                <AlertTitle>Для обновления уже установленной tvcas4</AlertTitle>
                <code>curl -sS https://tvcas.com/update.sh | bash</code>
            </Alert>

            <p>
                Не пытайтесь запускать на старых версиях Linux. В этом случае практически наверняка будет ошибка библиотек <b>GLIBC_2.XX</b>.
                Если выполнены требования по операционной системе (Debian 11 и новее),
                то после первого запуска рядом с исполняемым файлом будет создана база данных <b>db.sqlite3</b>,
                необходимая для хранения информации.
                При изменении конфигурации TVCAS4 (и по внутреннему таймеру каждые сутки) делается резервная копия данной БД под именем <b>_db.sqlite3</b>.
                Если у вас есть необходимость в непосредственном администрировании базы,
                то рекомендуем бесплатную кроссплатформенную программу <a href="https://sqlitebrowser.org" rel="noreferrer" target="_blank">SQLite browser</a>.
            </p>
            <p>
                По умолчанию, сервер TVCAS4 запускается на порту <b>:8080</b>.
                Номер порта можно изменить в конфигурационном разделе.
                Как и в прежних версиях, в системе два пользователя:
            </p>
            <ul>
                <li>Суперпользователь: <b>admin / admin</b> - все права</li>
                <li>Оператор: <b>oper / oper</b> - ограниченные права</li>
            </ul>
            <p>
                Также после первого запуска программы создаётся юнит <b>systemd</b> (файл <b>/etc/systemd/system/tvcas4.service</b>) для автозапуска сервера.
                Если вам необходим автозапуск сервера, то выполните активацию данного юнита.
            </p>
            <Alert>
                <AlertTitle>Активация юнита автозапуска</AlertTitle>
                <code>
                    systemctl enable tvcas4<br />
                    systemctl start tvcas4
                </code>
            </Alert>

            <Alert severity="info" style={{marginTop:"20px"}}>
                <AlertTitle>Управление процессом</AlertTitle>
                <code>
                    systemctl status tvcas4<br />
                    systemctl restart tvcas4<br />
                    systemctl stop tvcas4<br />
                    systemctl disable tvcas4
                </code>
            </Alert>

            <p>
                Обратите внимание, чтобы в последующем перенести файлы <b>tvcas4</b> в другое место на диске,
                сначала остановите процесс, выполните перенос, потом <u>измените пути в файле юнита</u>, обновите конфигурацию systemd: <code>systemctl daemon-reload</code>
                и лишь затем вновь активируйте юнит.
            </p>

            <Typography variant="h5" style={{marginTop:"50px"}}><ElectricBoltIcon /> Начальные настройки</Typography>

            <p>
                После входа в систему, перейдите в <ImageModal url="/sources/config.jpg">конфигурационный раздел</ImageModal> и измените настройки системы: пароли, ECM-ключ, CAID кодировок и прочее.
                Обратите внимание, что потом при наличии <span style={{fontWeight:"bold", color:"red"}}>хотя бы одной прошитой смарткарты</span> в биллинге системы, <span style={{fontWeight:"bold", color:"red"}}>ECM-ключ изменить невозможно</span>!
                Также стоит отметить, что в отличие от ранних версий, в TVCAS4 все ключи хранятся в зашифрованном виде.
                Поэтому, если вы мигрируете с TVCAS3, то ECM-ключ необходимо конвертировать с помощью программы <ImageModal url="/sources/converter.jpg">converter.exe</ImageModal>, находящейся <a href="https://tvcas.com/converter.exe">здесь</a>. При импорте CSV-файла система сама распознаёт,
                что он от TVCAS3 и автоматически сделает это за вас с EMM-ключами смарткарт.
            </p>


            <h2><ElectricBoltIcon /> Настройка скремблера Cesbo Astra</h2>

            <p>
                При разработке проекта в качестве скремблера использовалось программное обеспечение <a href="https://cesbo.com" rel="noreferrer">Cesbo Astra</a>.
                Настройка данного скремблера сводится к созданию "CAS", а затем созданная CAS присваивается сервисам (потокам телеканалов).
                Смотрите картинки ниже и всё станет понятно..
            </p>


            <Grid container spacing={2}>
            <Grid item xs={4} >
                <Item step='1'/>
            </Grid>
            <Grid item xs={4} >
                <Item step='2' />
            </Grid>
            <Grid item xs={4} >
                <Item step='3' />
            </Grid>
            </Grid>

            <p>
            После завершения настроек необходимо перезапустить скремблер, потому как созданный EMM-порт открывается только при запуске Cesbo Astra.
            Если вы всё сделали правильно, то в генераторах мы увидим подключения.
            </p>

            <div align="center" style={{marginBottom:"10px"}}><img src='/sources/ecmg_connect.jpg' alt='' /></div>
            <div align="center"><img src='/sources/emmg_connect.jpg' alt='' /></div>




            <Dialog
                open={openModal}
                onClose={handleClose}
                maxWidth={"lg"}
                >   
                <DialogContent>
                    <img src={"/sources/astra_step"+stepModal+".jpg"} alt='' width="100%" />
                </DialogContent>
            </Dialog>
            



            <Dalee url="interface" />

        </>
    )
}